import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
const classNames = require('classnames')

import {
  buildTickerText,
  isUsgaThemeRestrictedTournament,
  hexToRgb,
  isListViewBracket,
  isMatchPlay,
  fixHeaderTranslationUsgaTheme,
} from '../../helpers'
import {
  changeTeamColumnDisplay,
  removeAMPMFromTime,
  getMaxAbbrevationLength,
  adjustColWidth,
  adjustCutListColspan,
} from '../../usga_theme_helpers/stroke_format'
import {
  applyMatchChangesHtmlInjection,
  applyMatchChangesForPage,
  changeMatchBackgroundOpacity,
} from '../../usga_theme_helpers/match_format'
import {
  COMPLETE,
  LEADERBOARD_HEADER_SELECTOR,
  MATCH_FORMAT_NODE_ID,
  SCOPED_LEADERBOARD_COMPETITION_NODE_IDS,
  MATCH_SCOPED_LEADERBOARD_COMPETITION_NODE_IDS,
  USGA_SILVER,
  USGA_OLD_BLUE,
  BLUE_GREY,
  GREY,
  MOON_GREY,
  ALPINE_BLUE,
} from '../../constants'

class LeaderboardComponent extends Component {

  constructor(props) {
    super(props)

    this.initialState = {
      currentDivisionPosition: 0,
      currentFlightPosition: 0,
      currentDivisionFlightNames: [],
      currentBracketRegionPosition: 0,
      currentPage: 0,
      bothAMPM: null,
      maxAbbrevationLength: null,
      totalPages: 0,
      currentBracketRows: 0,
      currentBracketColumns: 0,
      bracketRowToDisplay: 0,
      bracketColumnToDisplay: 0,
      totalBracketRows: 0,
      needToFetchAgain: false,
      applyUSGATheme: this.props.hasUSGATheme && isUsgaThemeRestrictedTournament(this.props.slide, 0),
      matchPlay: isMatchPlay(this.props.slide, 0),
    }

    this.state = this.initialState

    this.setStateProperty = this.setStateProperty.bind(this)
  }

  setStateProperty(property, value) {
    if (this._isMounted && this.state[property] !== value) {
      this.setState({
        [property]: value,
      })
    }
  }

  moveToNextPage() {
    this.setStateProperty('currentPage', (this.state.currentPage + 1) % this.state.totalPages)
  }

  resetCurrentPage() {
    this.setStateProperty('currentPage', 0)
  }

  moveToNextFlight(totalFlights) {
    this.setStateProperty('currentFlightPosition', (this.state.currentFlightPosition + 1) % totalFlights)
  }

  resetCurrentFlight() {
    this.setStateProperty('currentFlightPosition', 0)
  }

  moveToNextRegion(totalBracketRegions) {
    this.setStateProperty('currentBracketRegionPosition', (this.state.currentBracketRegionPosition + 1) % totalBracketRegions)
  }

  resetCurrentBracketRegion() {
    this.setStateProperty('currentBracketRegionPosition', 0)
  }

  moveToNextDivision() {
    if (this._isMounted) {
      this.setState({
        currentDivisionPosition: (this.state.currentDivisionPosition + 1) % this.props.slide.divisions.length,
      }, this.computeDivisionFlightNames)
    }
  }

  resetCurrentDivision() {
    this.setStateProperty('currentDivisionPosition', 0)
  }

  setCurrentBracketRegion(row, column) {
    if (this._isMounted) {
      this.setState({
        bracketRowToDisplay: row,
        bracketColumnToDisplay: column,
      })
    }
  }

  setCurrentBracketRegionSize(rows, columns) {
    if (this._isMounted) {
      this.setState({
        currentBracketRows: rows,
        currentBracketColumns: columns,
      })
    }
  }

  computeDivisionFlightNames() {
    const { slide } = this.props

    if (slide.displayType === 'table') {
      const flightNames = (slide.flightsByDivisionId[slide.divisions[this.state.currentDivisionPosition].id] || [])
                            .map( 
                              flight => { 
                                const trimmedLabel = flight.label.replace(/\s+/g, ' ').trim()
                                if (flight.label.toLowerCase().includes('flight')) {
                                  return trimmedLabel 
                                } else {
                                  return `${trimmedLabel} ${!window.I18n ? '' : window.I18n.t('tv_shows.components.slideshow.leaderboard.flight')}`
                                }
                              }
                            )
      this.setStateProperty('currentDivisionFlightNames', flightNames)
    }
  }

  injectHtmlInPage() {
    const {
      slide,
      containerId,
      blueBox,
    } = this.props
    const { 
      currentDivisionPosition, 
      applyUSGATheme,
      matchPlay,
    } = this.state

    let htmlResult = ''
    const containerSelector = `main ${containerId} .current-slide-data`

    if (slide.displayType === 'table') {
      htmlResult = slide.divisions[currentDivisionPosition].content

      // remove 'Expand All' link
      htmlResult = htmlResult.replace(/<a.*class=".*expand-all.*".*<\/a>/gi, '')
                                        .trim()
      // remove 'Collapse All' link
      htmlResult = htmlResult.replace(/<a.*class=".*collapse-all.*".*<\/a>/gi, '')
                                        .trim()

      // remove Total Purse Allocated row
      htmlResult = htmlResult.replace(/<tr class='header total_purse_allocated'>(.*\n)*<\/tr>/gi, '')
                             .trim()

      htmlResult = fixHeaderTranslationUsgaTheme(htmlResult, applyUSGATheme)
      
      // remove info circles
      htmlResult = htmlResult.replace(/<i class='fa fa-info-circle'.*>(.*\n)*<\/i>/gi, '')
                             .trim()

      if (!matchPlay && applyUSGATheme) {
        htmlResult = htmlResult.replace(/data-display-today='true'/gi, '')
        .trim()
      }

      $(containerSelector).html(htmlResult)

      // if we have Flights, remove the ones that shouldn't be displayed
      if ( $(`${containerId} .current-slide-data tr.header:has(td.scope_name)`).length > 0 ) {
        const flightHeaders = [ ...$(`${containerId} .current-slide-data tr.header:has(td.scope_name)`), '' ]

        for (let i = 0; i < flightHeaders.length - 1; i++) {
          const currentFlightHeader = $(flightHeaders[i]).text()
                                                         .replace(/\s+/g, ' ')
                                                         .trim()
          const currentFlightHeaderOptions = [ currentFlightHeader, `${currentFlightHeader} ${window.I18n.t('tv_shows.components.slideshow.leaderboard.flight')}` ]
          if (!currentFlightHeaderOptions.some(el => this.state.currentDivisionFlightNames.includes(el))) {
            $(flightHeaders[i]).nextUntil(flightHeaders[i + 1])
                                .addBack()
                                .remove()
          }
        }
      }

      if (!applyUSGATheme && !matchPlay) {
        adjustCutListColspan($(containerSelector))
      }

      if (applyUSGATheme && !matchPlay) {
        this.renameTableHeader($(containerSelector), slide)
        this.changeColumnsForUSGATheme($(containerSelector))
        this.changeNumeralsColor($(containerSelector))
        changeTeamColumnDisplay($(containerSelector))
        this.setStateProperty('bothAMPM', removeAMPMFromTime($(containerSelector)))
        this.setStateProperty('maxAbbrevationLength', getMaxAbbrevationLength($(containerSelector)))
      }
      this.moveNameContentIntoSpan(containerId, containerSelector)
    } else if (slide.displayType === 'brackets') {
      htmlResult = slide.events.reduce((acc, eventData) => acc + eventData.content, '')
      htmlResult = fixHeaderTranslationUsgaTheme(htmlResult, applyUSGATheme)

      $(containerSelector).html(htmlResult)
      slide.events.forEach((eventData) => {
        window.glg.tournaments2_tv.selectRelevantForTVDisplay(
          slide.tvDisplayMode,
          slide.tvDisplayHbhScores,
          eventData.id,
          true,
          slide.bracketIds,
        )
      })
    }
    this.limitShownPlaces()
    if (applyUSGATheme && matchPlay) {
      applyMatchChangesHtmlInjection(containerId, containerSelector, slide, blueBox.fontSize)
    }
    this.setNameColWidth(containerSelector, applyUSGATheme)
  }

  // Limit the number of places shown on the leaderboard. For TV Display, this is done in the tournaments2.continuousProcessing method.
  limitShownPlaces() {
    $('tr[data-display-tv=false]').remove()
    $('.resolve_ties_button').remove()
  }

  setNameColWidth(containerSelector, applyUSGATheme) {
    if (!applyUSGATheme) {
      const row = $(`${containerSelector} table:not(.ryder) tr.aggregate-row`)[0]
      const columnsNo = $(row).children().length
      if (columnsNo > 5) {
        $(`${containerSelector} th.name`).css('width', '25%')
      } else {
        $(`${containerSelector} th.name`).css('width', '50%') 
      }
    }
  }

  /**
   * Move the content of a 'name' table cell inside a span element. Necessary for text resize.
   *
   * @param {string} containerId - one of ['', '#left', '#right]
   * @param {string} containerSelector - `main ${containerId} .current-slide-data`
   */
  moveNameContentIntoSpan(containerId, containerSelector) {
    const nameCells = $(`${containerSelector} table:not(.ryder) td.name`)
    for (let i = 0; i < nameCells.length; i++) {
      let id = `span-${containerId.substring(1)}${i}`
      $(nameCells[i]).append(`<span id="${id}" class="vertical-middle"></span>`)
      id = '#' + id
      $(nameCells[i]).children()
        .not(id)
        .not('.flags, .logo-area, .image-wrapper, .display-custom-trophy')
        .appendTo(`${containerSelector} ${id}`)
      $(nameCells[i])
        .children('.display-custom-trophy')
        .appendTo($(nameCells[i]))
    }
  }

  shrinkText(nameContainer, textChild) {    
    while (nameContainer.scrollWidth > nameContainer.offsetWidth) {
      let currentFontSize = $(textChild).css('font-size')
      currentFontSize = parseInt(currentFontSize)
      if (currentFontSize <= 10) {
        let a
        if ($(textChild).hasClass('match-play-name')) {
          a = $(textChild).children('a')
        } else if ($(nameContainer).hasClass('ticker-display')) {
          a = $(textChild).children('p')
        } else {
          a = $(textChild)
            .children('div')
            .children('a')
        }
        if ($(a).length !== 0) {
          while ($(a).text().length > 30 && nameContainer.scrollWidth > nameContainer.offsetWidth) {
            let text = $(a).text()
            text = text.substring(0, text.length - 6) + '...'
            $(a).text(text)
          }
        }
        break
      }
      $(textChild).css('font-size', currentFontSize - 1)
    }
    if ($(textChild).children('.team').length > 0) {
      const noPlayers = $(textChild)
                      .children('.team')
                      .children('div').length
      $(textChild)
      .children('.team')
      .children('div')
      .toArray()
      .forEach((player) => {
        while (player.scrollWidth > nameContainer.offsetWidth / noPlayers){
          let currentFontSize = $(textChild).css('font-size')
          currentFontSize = parseInt(currentFontSize)
          $(textChild).css('font-size', currentFontSize - 1)
        }
      })
    }
  }

  /**
   * @param {string} containerSelector - one of [`main ${containerId} .current-slide-data`, `main ${containerId} .current-slide-display`]
   */
  scaleNamesToFit(containerSelector) {
    $(`
      ${containerSelector} table.ryder .match-play-name-container,
      ${containerSelector} table:not(.ryder) td.name,
      ${containerSelector} .bracket-container .text.above .text_box
    `).toArray()
      .forEach((nameContainer) => {
        // Select the child containing only text, without flags.
        let textChild = $(nameContainer).children('.match-play-name')
        if (textChild.length === 0) {
          // Non-match table
          textChild = $(nameContainer).children('span')
        }
        this.shrinkText(nameContainer, textChild)
      })

    $(`${containerSelector} table:not(.ryder) th`)
      .toArray()
      .forEach((th) => this.shrinkText(th, th))
  }

  scaleTickerToFit(ticker, tickerDisplay) {
    if (tickerDisplay === 'scrolling') {
      return
    }
    ticker.toArray().forEach((tickerContainer) => {
      const textChild = $(tickerContainer)
      this.shrinkText(tickerContainer, textChild)
    })
  }

  changeColumnsForUSGATheme(tableToDisplay) {
    // show "18" instead of "F"s on the 'thru' column
    // const completedHoles = $(tableToDisplay).find('table.result_scope td.past_round_thru:contains("F"), table.result_scope td.thru:contains("F")')
    // completedHoles.text('18')

    // delete table columns besides pos, name, TOTAL, THRU, ROUND NO. (round to par)
    const columnsToKeepByClass = [ 'pos', 'name', 'score', 'past_round_thru', 'thru', 'past_round_to_par', 'scope_name' ]
    const rows = $(tableToDisplay).find('tr.header, tr.aggregate-row')
    if (rows.length === 0) {
      return
    }
    const header = rows[0].cells
    for (let j = 0; j < header.length; j++) {
      let keepColumn = false
      for (let k = 0; k < columnsToKeepByClass.length; k++) {
        const className = columnsToKeepByClass[k]
        if (header[j].classList.contains(className)) {
          keepColumn = true
        }
      }
      if (keepColumn === false) {
        for (let i = 0; i < rows.length; i++) {
          if (j < rows[i].cells.length) {
            rows[i].deleteCell(j)
          }
        }
        j-- // to not skip next column, which is moved in the place of this one
      }
    }
    adjustCutListColspan(tableToDisplay)
  }
  
  changeNumeralsColor(tableToDisplay) {
    const numerals = $(tableToDisplay).find('table.result_scope tr.aggregate-row td')
                                      .not('.pos, .name')
    for (let i = 0; i < numerals.length; i++) {
      const numeral = $(numerals[i]).text()
                                    .trim()
      $(numerals[i]).css('color', '')
      if (numeral.substring(0, 1) === '-' && numeral.length > 1) {
        $(numerals[i]).addClass('usga-red')
      } else if (numeral.substring(0, 1) === '+') {
        $(numerals[i]).addClass('usga-black')
      } else {
        $(numerals[i]).addClass('usga-blue')
      } 
    }
  }

  renameTableHeader(tableToDisplay, slide) {
    $(tableToDisplay).find('table.result_scope tr.header')
                      .css('height', 'unset')
    // do not show 'Pos.' column header
    const positionColumnName = $(tableToDisplay).find('table.result_scope tr.header th.pos')
    positionColumnName.text('')

    const roundToParHeaders = $(tableToDisplay).find('table.result_scope tr.header th.past_round_to_par')
    const roundTotalHeaders = $(tableToDisplay).find('table.result_scope tr.header th.past_round_total')
    const collegeScoring = slide.divisions[this.state.currentDivisionPosition].collegeScoring

    for (let i = 0; i < roundToParHeaders.length; i++) {
      let roundTotalHeaderName
      if (collegeScoring && roundTotalHeaders.length === 0) {
        roundTotalHeaderName = 'R0'
      } else {
        roundTotalHeaderName = roundToParHeaders.length === 1 ? $(roundTotalHeaders[roundTotalHeaders.length - 1]).text() : $(roundTotalHeaders[i]).text()
      }
      if (roundTotalHeaderName.substring(0, 1) === 'R') {
        const roundNumber = collegeScoring ? Number(roundTotalHeaderName.substring(1)) : Number(roundTotalHeaderName.substring(1)) - 1
        $(roundToParHeaders[i]).text(slide.roundNames[roundNumber])
      }
    }
    window.glg.translation.tournament2_event(true, window.I18n.locale)
  }

  fetchCurrentPage() {
    const { slide, containerId, blueBox, isFireTv } = this.props
    const { applyUSGATheme, matchPlay } = this.state

    if (isFireTv && document.readyState !== COMPLETE) {
      setTimeout( () => this.setStateProperty('needToFetchAgain', true), 1000)
      return
    }
    const displayHeight = $('main').height()
    const displayWidth = $(`main ${containerId}`).width()
    const containerSelector = `main ${containerId} .current-slide-display`
    const containerDataSelector = `main ${containerId} .current-slide-data`

    if (slide.displayType === 'table' || isListViewBracket(slide)) {
      $(`${containerId} .current-slide-data .result_scope`).css({ 'font-size': `${blueBox.fontSize}px` })
      const heightArrow = $(`${containerId} .current-slide-data .list-of-matches`).find('tr.aggregate-row.odd')
                                                                                  .height() 
      $(`${containerId} .current-slide-data .score-content`).attr('class', `score-content height-${heightArrow}`)
      $(`${containerId} .ticker-display`).css({ 'font-size': `${blueBox.fontSize}px` })
      const bothAMPM = this.state.bothAMPM === null ? removeAMPMFromTime(containerDataSelector) : this.state.bothAMPM
      const maxAbbrevationLength = this.state.maxAbbrevationLength === null ? getMaxAbbrevationLength(containerDataSelector) : this.state.maxAbbrevationLength
      adjustColWidth(containerDataSelector, this.props.blueBox.fontSize, bothAMPM, maxAbbrevationLength, applyUSGATheme, matchPlay)
      const pageRows = this.computePageRows(displayHeight)

      let tableToDisplay = $(`${containerId} .current-slide-data .list-of-matches`).clone()
      if (isListViewBracket(slide)) {
        tableToDisplay = tableToDisplay[0]
      }
      $(tableToDisplay).find('table.result_scope tbody')
                        .html(pageRows)

      if (applyUSGATheme && !matchPlay) {
        this.removeTiedPositions(tableToDisplay)
      }
      this.changeBackgroundOpacity(tableToDisplay, slide, blueBox.colorTheme)
      $(containerSelector).html(tableToDisplay)
      this.setNameColWidth(containerSelector, applyUSGATheme)
    } else if (slide.displayType === 'brackets') {
      if ($('.bracket-container').length === 0) {
        return 
      }
      const pageSection = this.computeBracketsSection(displayHeight, displayWidth)
      const bracketsToDisplay = $(`${containerId} .current-slide-data .bracket-placeholder`).clone()
      $(bracketsToDisplay).find('.bracket-container')
                            .html(pageSection)

      $(containerSelector).html(bracketsToDisplay)
    }
    if (applyUSGATheme && matchPlay) {
      applyMatchChangesForPage(applyUSGATheme, containerSelector, slide, blueBox.colorTheme, blueBox.fontSize)
    }
    this.scaleNamesToFit(containerSelector)
    const ticker = $(`main ${containerId} .ticker-display`)
    this.scaleTickerToFit(ticker, blueBox.tickerDisplay)
  }

  // Print rank only for the first player for any tied rank
  removeTiedPositions(tableToDisplay) {
    const tiedPositions = $(tableToDisplay).find('table.result_scope td.pos:contains("T")')
    let position = ''
    for (let i = 0; i < tiedPositions.length; i++) {
      const currentPosition = $(tiedPositions[i]).text()
                                                .trim()
                                                .replace(/T([0-9]+)/i, '$1')
      if (currentPosition === position) {
        $(tiedPositions[i]).text('')
      } else {
        $(tiedPositions[i]).text(currentPosition)
        position = currentPosition
      }
    }
  }

  changeBackgroundOpacity(tableToDisplay, slide, colorTheme) {
    if ($(tableToDisplay).find('table.ryder').length > 0) {
      return
    }
    const bgOpacity = parseInt(slide.backgroundOpacity || 100) / 100
    let borderColor = ''
    let rgb = {}
    if (this.state.applyUSGATheme) {
      borderColor = bgOpacity >= 0.3 ? BLUE_GREY : 'transparent'
      rgb = colorTheme === 'light' ? hexToRgb(USGA_SILVER) : hexToRgb(USGA_OLD_BLUE)
    } else {
      borderColor = 'transparent'
      if (bgOpacity >= 0.3) {
        borderColor = colorTheme === 'dark' ? BLUE_GREY : GREY
      }
      rgb = colorTheme === 'light' ? hexToRgb(MOON_GREY) : hexToRgb(ALPINE_BLUE)
    }
    const background = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${bgOpacity})`
    const isFirefox = typeof InstallTrigger !== 'undefined'
    const bgClip = isFirefox ? 'padding-box' : 'initial'

    $(tableToDisplay).find('tr.aggregate-row td')
                      .not('.past_round_thru, .thru')
                      .css({
                        'background': `${background}`,
                        'border-color': `${borderColor}`,
                        'background-clip': `${bgClip}`,
                      })
    const thruCellStyle = `background: ${background} !important; border-color: ${borderColor}; background-clip: ${bgClip};`
    $(tableToDisplay).find('tr.aggregate-row td.past_round_thru, tr.aggregate-row td.thru')
                      .attr('style', thruCellStyle)      
  }

  /**
   * The height() and outerHeight() functions return the integer part.
   * Losing the fractional part affects the distribution of rows per page.
   * Round up the height by 1 px (better to assume we have a little less space available than we actually have).
   * 
   * @param {number|null} heightIntPart - the height (integer part) of a jQuery element
   * @return {number} 
   */
  roundUp(heightIntPart) {
    return heightIntPart === null ? 0 : heightIntPart + 1
  }

  computePageRows(displayHeight) {
    const { containerId, minHeights, slide } = this.props
    let pageRows
    const slideHeader = this.roundUp($(`${containerId} .slide_header`).outerHeight())
    const tickerHeight = this.roundUp($(`${containerId} .ticker-display`).height())
    const purseHeaderHeight = this.roundUp($('.total_purse_allocated').height())
    const pointsHeaderHeight = this.roundUp($('tr.header.thead.total_points_allocated').height())
    const currentDivisionFlightsCount = $(`${containerId} .current-slide-data tr.header:has(td.scope_name)`).length

    // Set minimum heights
    // flight header
    if (minHeights.flightsHeader > 0) {
      $(`${containerId} .current-slide-data tr.header:has(td.scope_name)`).css({ 'height': `${minHeights.flightHeaders}px` })
    }
    // header
    if (minHeights.header > 0) {
      $(`${containerId} .current-slide-data ${LEADERBOARD_HEADER_SELECTOR}`).css({ 'height': `${minHeights.header}px` })
    }
    // player row
    if (minHeights.row > 0) {
      $(`${containerId} .current-slide-data table.result_scope tr.aggregate-row`).css({ 'height': `${minHeights.row}px` })
    }

    if (currentDivisionFlightsCount > 0 || isListViewBracket(slide)) {
      const remainingHeight = displayHeight - slideHeader - tickerHeight
      if (currentDivisionFlightsCount > 0) {
        // If we have a scoped table (e.g. with Flights)
        pageRows = this.computeCurrentFlight(containerId, remainingHeight)
      } else {
        pageRows = this.computeCurrentBracketRegion(containerId, remainingHeight)
      }
    } else if ( slide.tournamentFormatNodeId !== MATCH_FORMAT_NODE_ID && SCOPED_LEADERBOARD_COMPETITION_NODE_IDS.includes(slide.tournamentCompetitionNodeId) ||
                slide.tournamentFormatNodeId === MATCH_FORMAT_NODE_ID && MATCH_SCOPED_LEADERBOARD_COMPETITION_NODE_IDS.includes(slide.tournamentCompetitionNodeId) ) {
      // Multiple mini-leaderboard inside this table.
      // The leaderbord format resembles the behaviour of multiple Result Scopes, but it's not coded as such.
      // There are simply multiple table headers within this table, each header having 2-4 rows underneath containing Players / Pairs / Foursomes
      const multipleHeaders = [ ...$(`${containerId} .current-slide-data tr.header:not(:has(td.cut_list_tr))`), '' ]
      let maxInnerTableHeight = 0
      let innerTableHeight = 0
      for ( let i = 0; i < multipleHeaders.length - 1; i++ ) {
        innerTableHeight = $(multipleHeaders[i]).nextUntil(multipleHeaders[i + 1])
                                          .addBack()
                                          .filter('.aggregate-row, .header')
                                          .toArray()
                                          .reduce( (acc, val) => acc + $(val).height(), 0)
        maxInnerTableHeight = Math.max(innerTableHeight, maxInnerTableHeight)
      }
      const dataPageSize = Math.floor( (displayHeight - slideHeader) / maxInnerTableHeight )
      const totalPages = Math.ceil( (multipleHeaders.length - 1) / dataPageSize )
      if (totalPages !== this.state.totalPages) {
        this.setStateProperty('totalPages', totalPages)
      }

      pageRows = []
      multipleHeaders.filter( header => header !== '' )
             .splice(this.state.currentPage * dataPageSize, dataPageSize)
             .forEach( (header, i) => {
               pageRows = [
                 ...pageRows,
                 ...$(multipleHeaders[this.state.currentPage * dataPageSize + i]).nextUntil(multipleHeaders[this.state.currentPage * dataPageSize + i + 1])
                                         .addBack()
                                         .clone()
                                         .toArray(),
               ]
             })

    } else {
      // Plain table. Fetch however many rows fit the displayHeight
      const headerHeight = this.roundUp($(`${containerId} .current-slide-data ${LEADERBOARD_HEADER_SELECTOR}`).height())
      const dataRows = `${containerId} .current-slide-data tr.aggregate-row, ${containerId} .current-slide-data tr.site_no_res, ${containerId} .current-slide-data tr:has(td.cut_list_tr)`
      const dataRowHeight = this.roundUp($(dataRows).height())
      const dataPageSize = Math.floor((displayHeight - slideHeader - headerHeight - tickerHeight - purseHeaderHeight - pointsHeaderHeight) / dataRowHeight)
      const totalPages = Math.ceil($(dataRows).length / dataPageSize)
      if (totalPages !== this.state.totalPages) {
        this.setStateProperty('totalPages', totalPages)
      }

      pageRows = $(`${containerId} .current-slide-data tr.header:not(:has(td.cut_list_tr)):not(.table_bottom)`).clone()
                                      .toArray()
                                      .concat(
                                        $(dataRows).clone()
                                                    .splice(this.state.currentPage * dataPageSize, dataPageSize)
                                      )
      const bottomHeaders = $(`${containerId} .current-slide-data tr.header.table_bottom:not(:has(td.cut_list_tr))`)
                              .clone()
                              .toArray()
      pageRows = pageRows.concat(bottomHeaders)                           
    }

    return pageRows
  }

  computeCurrentFlight(containerId, remainingHeight) {
    const currentPosition = this.state.currentFlightPosition
    const flightHeaders = [ ...$(`${containerId} .current-slide-data tr.header:has(td.scope_name)`), '' ]
    const currentFlightHtml = $(flightHeaders[currentPosition])
                                .nextUntil(flightHeaders[currentPosition + 1])
                                .addBack()

    const flightHeaderHeight = $(flightHeaders[currentPosition]).height() // e.g: "Flight 1"
    const leaderboardHeaderHeight = $(`${containerId} .current-slide-data ${LEADERBOARD_HEADER_SELECTOR}`).height()
    const headersHeight = this.roundUp(flightHeaderHeight) + this.roundUp(leaderboardHeaderHeight)
    return this.computeFlightOrBracketPage(currentFlightHtml, remainingHeight, headersHeight)
  }

  // Bracket Region in List View
  computeCurrentBracketRegion(containerId, remainingHeight) {
    const currentPosition = this.state.currentBracketRegionPosition
    const headers = [ ...$(`${containerId} .current-slide-data tr.header`), '' ]
    const currentRegionHtml = $(headers[currentPosition])
                                .nextUntil(headers[currentPosition + 1])
                                .addBack()

    const headerHeight = this.roundUp($(headers[currentPosition]).height())
    return this.computeFlightOrBracketPage(currentRegionHtml, remainingHeight, headerHeight)
  }

  computeFlightOrBracketPage(currentTableHtml, remainingHeight, headerHeight) {
    const dataRows = $(currentTableHtml).filter('tr.aggregate-row, tr.site_no_res, tr:has(td.cut_list_tr)')
    const dataRowHeight = this.roundUp($(dataRows).height())

    const dataPageSize = Math.floor((remainingHeight - headerHeight) / dataRowHeight)
    const totalPages = Math.ceil($(dataRows).length / dataPageSize)

    if (totalPages !== this.state.totalPages) {
      this.setStateProperty('totalPages', totalPages)
    }
    const pageRows = $(currentTableHtml).filter('tr.header:not(:has(td.cut_list_tr))')
                              .clone()
                              .toArray()
                              .concat(
                                $(dataRows).clone()
                                            .splice(this.state.currentPage * dataPageSize, dataPageSize)
                              )
    return pageRows
  }

  // Bracket View
  computeBracketsSection(displayHeight, displayWidth) {
    const { containerId } = this.props
    const slideHeader = $(`${containerId} .slide_header`).outerHeight()
    const headerHeight = $(`${containerId} .current-slide-data h2.bracket_name`).outerHeight() +
                         $(`${containerId} .current-slide-data .column .round_name`).outerHeight()

    // retrieving height using jQuery leads to unexpected results. Using HTML DOM instead
    const rowHeight = document.getElementsByClassName('match')[0].getBoundingClientRect().height
    const columnSearchScope = containerId.length > 0 ? document.getElementById(containerId.replace('#', '')) : document
    const maxColumnWidth = Math.max.apply(null, Array.prototype.map.call(
      columnSearchScope.getElementsByClassName('current-slide-data')[0].getElementsByClassName('column'),
      el => el.getBoundingClientRect().width)
    )

    // test column width fitting first.
    let maxColumnsPerPage = Math.floor(displayWidth / maxColumnWidth)
    let maxRowsPerPage = Math.max( Math.floor((displayHeight - slideHeader - headerHeight) / rowHeight), 1)
    maxColumnsPerPage = Math.min( maxColumnsPerPage, Math.floor( Math.log2(maxRowsPerPage) ) + 1)

    // test row fitting in given column size above.
    maxRowsPerPage = Math.min( maxRowsPerPage, Math.pow(2, maxColumnsPerPage - 1) )

    // test if the above structure can be multiplied vertically.
    maxRowsPerPage *= Math.floor( (displayHeight - slideHeader - headerHeight) / (rowHeight * maxRowsPerPage) )

    if ( this.state.currentBracketRows !== maxRowsPerPage || this.state.currentBracketColumns !== maxColumnsPerPage ) {
      this.setCurrentBracketRegionSize(maxRowsPerPage, maxColumnsPerPage)
    }

    const totalBracketRows = $(`${containerId} .current-slide-data .column.first .match`).length
    if ( this.state.totalBracketRows !== totalBracketRows ) {
      this.setStateProperty('totalBracketRows', totalBracketRows )
    }

    return $(`${containerId} .current-slide-data .column`).clone()
                                            .get()
                                            .slice(this.state.bracketColumnToDisplay, this.state.bracketColumnToDisplay + maxColumnsPerPage)
                                            .map( (column, index) => {
                                              const startingRowIndex = this.state.bracketRowToDisplay / Math.pow(2, index)
                                              const roundName = $(column).children('.round_name')
                                              const rows = $(column).children('.match')
                                                                  .slice(startingRowIndex, startingRowIndex + maxRowsPerPage / Math.pow(2, index) )

                                              return $(column).html( roundName.get()
                                                .concat(rows)
                                              )
                                            })
  }

  showNextPage() {
    const {
      slide,
      containerId,
      moveToNextSlide,
    } = this.props

    const {
      currentPage,
      totalPages,
      currentFlightPosition,
      currentDivisionPosition,
      currentBracketRegionPosition,
      bracketRowToDisplay,
      currentBracketRows,
      currentBracketColumns,
      bracketColumnToDisplay,
      totalBracketRows,
    } = this.state

    if (slide.displayType === 'table' || isListViewBracket(slide)) {

      const currentSlideDivisionsCount = slide.divisions.length
      const totalFlights = $(`${containerId} .current-slide-data tr.header:has(td.scope_name)`).length
      const totalBracketRegions = $(`${containerId} .current-slide-data .bracket-placeholder`).length

      if (currentPage < totalPages - 1) {
        this.moveToNextPage()
      } else {
        if (currentFlightPosition < totalFlights - 1 ) {
          this.moveToNextFlight(totalFlights)
        } else {
          if (currentBracketRegionPosition < totalBracketRegions - 1) {
            this.moveToNextRegion(totalBracketRegions)
          } else {
            if (currentDivisionPosition < currentSlideDivisionsCount - 1) {
              this.moveToNextDivision()
            } else {
              this.resetCurrentDivision()
              moveToNextSlide()
            }
            this.resetCurrentBracketRegion()
          }
          this.resetCurrentFlight()
        }
        this.resetCurrentPage()
      }
    } else if (slide.displayType === 'brackets') {
      let nextRow = bracketRowToDisplay + currentBracketRows
      let nextColumn = bracketColumnToDisplay
      const totalColumns = Math.log2(totalBracketRows)

      if ( nextRow >= Math.pow(2, totalColumns - nextColumn) ) {
        nextRow = 0
        nextColumn += currentBracketColumns - 1
      }

      if (nextColumn > totalColumns) {
        moveToNextSlide()
        this.setCurrentBracketRegion(0, 0)
      } else {
        this.setCurrentBracketRegion(nextRow, nextColumn)
      }
    }
  }

  updateTickerDisplay() {
    const {
      slide,
      containerId,
    } = this.props

    if (
      slide.displayType === 'brackets' ||
      slide.tournamentFormatNodeId !== MATCH_FORMAT_NODE_ID && SCOPED_LEADERBOARD_COMPETITION_NODE_IDS.includes(slide.tournamentCompetitionNodeId) || 
      slide.tournamentFormatNodeId === MATCH_FORMAT_NODE_ID && MATCH_SCOPED_LEADERBOARD_COMPETITION_NODE_IDS.includes(slide.tournamentCompetitionNodeId) ||
      !slide.displayTicker
    ) {
      $(`${containerId} .ticker-display p`).text('')
      return
    }

    let searchScope = $(`${containerId} .current-slide-data tr.aggregate-row`)

    // if Flights are present
    if ( $(`${containerId} .current-slide-data tr.header:has(td.scope_name)`).length > 0 ) {
      const flightHeaders = [ ...$(`${containerId} .current-slide-data tr.header:has(td.scope_name)`), '' ]
      const currentFlightHtml = $(flightHeaders[this.state.currentFlightPosition])
                                  .nextUntil(flightHeaders[this.state.currentFlightPosition + 1])
                                  .addBack()
      searchScope = $(currentFlightHtml).filter('tr.aggregate-row')
    }

    let leaderRows = $(searchScope).get()
                                      .filter( (playerRow) => {
                                        return [ '1', 'T1' ].includes( $(playerRow).find('.pos')
                                                                                  .text()
                                                                                  .trim()
                                        )
                                      })

    const currentDivisionScoreFormat = slide.divisions[this.state.currentDivisionPosition].scoreFormat
    if (currentDivisionScoreFormat === 'Skins') {
      const leadingScore = Math.max( ...$(searchScope).find('.total')
                                                      .get()
                                                      .map( score => parseInt($(score).text()
                                                                                      .trim()
                                                      ))
      )
      leaderRows = $(searchScope).get()
                                 .filter( playerRow => parseInt( $(playerRow).find('.total')
                                                                              .text()
                                                                              .trim()
                                 ) === leadingScore
                                 )
    }

    const tickerDisplay = buildTickerText(leaderRows, currentDivisionScoreFormat)
    $(`${containerId} .ticker-display p`).text(tickerDisplay)
  }

  updateScrollRate() {
    const {
      blueBox,
      slide,
    } = this.props

    clearInterval(this.pageScrollInterval)
    this.pageScrollInterval = setInterval(
      () => this.showNextPage(),
      slide.scrollRate * 1000 / blueBox.scrollRate
    )
  }

  computePlaceholderValues() {
    const {
      slide,
      setPlaceholderValues,
    } = this.props

    if (setPlaceholderValues !== undefined) {
      let placeholderValues = {
        eventName: slide.eventName,
        tournamentName: slide.tournamentName,
        roundDate: slide.roundDate,
      }

      if (slide.displayType === 'table' || isListViewBracket(slide)) {
        placeholderValues = {
          ...placeholderValues,
          divisionName: slide.divisions[this.state.currentDivisionPosition].name,
        }
      }

      setPlaceholderValues(placeholderValues)
    }
  }

  componentDidMount() {
    this._isMounted = true

    this.computePlaceholderValues()
    this.computeDivisionFlightNames()
    this.injectHtmlInPage()
    this.fetchCurrentPage()
    this.updateTickerDisplay()
    this.updateScrollRate()
  }

  componentDidUpdate(prevProps, prevState) {
    const hasScrollingStateChanged = prevProps.blueBox.isScrolling !== this.props.blueBox.isScrolling
    if (hasScrollingStateChanged) {
      clearInterval(this.pageScrollInterval)
      if ( this.props.blueBox.isScrolling ) {
        this.pageScrollInterval = setInterval(
          () => this.showNextPage(),
          this.props.slide.scrollRate * 1000 / this.props.blueBox.scrollRate
        )
      }
      return
    }

    const hasScrollRateChanged = prevProps.blueBox.scrollRate !== this.props.blueBox.scrollRate ||
                                 prevProps.slide.scrollRate !== this.props.slide.scrollRate

    if (hasScrollRateChanged) {
      clearInterval(this.pageScrollInterval)
      this.pageScrollInterval = setInterval(
        () => this.showNextPage(),
        this.props.slide.scrollRate * 1000 / this.props.blueBox.scrollRate
      )
    }
    const hasColorThemeChanged = prevProps.blueBox.colorTheme !== this.props.blueBox.colorTheme
    const hasUSGAThemeChanged = prevProps.hasUSGATheme !== this.props.hasUSGATheme 

    const hasSlideChanged = prevProps.slide.id !== this.props.slide.id
    const hasDivisionChanged = prevState.currentDivisionPosition !== this.state.currentDivisionPosition
    const hasFlightChanged = prevState.currentFlightPosition !== this.state.currentFlightPosition
    const hasBracketRegionChanged = prevState.currentBracketRegionPosition !== this.state.currentBracketRegionPosition
    const haveFlightNamesChanged = !_.isEqual(prevState.currentDivisionFlightNames, this.state.currentDivisionFlightNames)

    const hasTablePageChanged = prevState.currentPage !== this.state.currentPage
    const hasBracketPageChanged = prevState.bracketRowToDisplay !== this.state.bracketRowToDisplay ||
                                  prevState.bracketColumnToDisplay !== this.state.bracketColumnToDisplay
    const hasPageChanged = hasTablePageChanged || hasBracketPageChanged

    const hasTableLeaderboardChanged = this.props.slide.displayType === 'table' &&
                                       prevProps.slide.id === this.props.slide.id &&
                                       (
                                         prevProps.slide.divisions.length !== this.props.slide.divisions.length ||
                                         prevProps.slide.divisions.map( (division, index) =>
                                           division.content !== this.props.slide.divisions[index].content
                                         ).reduce( (acc, value) => acc || value )
                                       )
    const hasBracketsLeaderboardChanged = this.props.slide.displayType === 'brackets' &&
                                          prevProps.slide.id === this.props.slide.id &&
                                          (
                                            prevProps.slide.events.length !== this.props.slide.events.length ||
                                            prevProps.slide.events.map( (prevEvent, index) =>
                                              prevEvent.content !== this.props.slide.events[index].content
                                            ).reduce( (acc, scoresUpdated) => acc || scoresUpdated )
                                          )

    const haveLeaderboardsChanged = hasTableLeaderboardChanged || hasBracketsLeaderboardChanged
    const hasFontSizeChanged = prevProps.blueBox.fontSize !== this.props.blueBox.fontSize
    const containerSelector = `main ${this.props.containerId} .current-slide-display`

    if (hasUSGAThemeChanged) {
      this.setState({
        applyUSGATheme: this.props.hasUSGATheme && isUsgaThemeRestrictedTournament(this.props.slide, this.state.currentDivisionPosition),
        matchPlay: isMatchPlay(this.props.slide, this.state.currentDivisionPosition),
      }, () => {
        this.injectHtmlInPage()
        this.updateTickerDisplay()
        this.fetchCurrentPage()
      })
      return
    }
    if (hasSlideChanged) {
      this.setState({ ...this.initialState,
        applyUSGATheme: this.props.hasUSGATheme && isUsgaThemeRestrictedTournament(this.props.slide, this.state.currentDivisionPosition),
        matchPlay: isMatchPlay(this.props.slide, this.state.currentDivisionPosition),
      }, () => {
        this.updateScrollRate()
        this.computeDivisionFlightNames()
        this.injectHtmlInPage()
        this.updateTickerDisplay()
        this.computePlaceholderValues()
        this.fetchCurrentPage()
      })
      return
    }

    if (hasDivisionChanged) {
      this.setState({
        applyUSGATheme: this.props.hasUSGATheme && isUsgaThemeRestrictedTournament(this.props.slide, this.state.currentDivisionPosition),
        matchPlay: isMatchPlay(this.props.slide, this.state.currentDivisionPosition),
      }, () => {
        this.computeDivisionFlightNames()
        this.injectHtmlInPage()
        this.updateTickerDisplay()
        this.computePlaceholderValues()
        this.fetchCurrentPage()
      })
      return
    }
    if (haveFlightNamesChanged || haveLeaderboardsChanged) {
      this.injectHtmlInPage()
      this.updateTickerDisplay()
      this.computePlaceholderValues()
      this.fetchCurrentPage()
      return
    }
    if (hasFlightChanged) {
      this.updateTickerDisplay()
      this.computePlaceholderValues()
      this.fetchCurrentPage()
      return
    }
    if (hasBracketRegionChanged) {
      this.updateTickerDisplay()
      this.computePlaceholderValues()
      this.fetchCurrentPage()
      return
    }
    if (hasPageChanged) {
      if (!this.props.slide.divisionName) {
        this.computePlaceholderValues()
      }
      this.fetchCurrentPage()
      return
    }
    if (hasColorThemeChanged) {
      const tableToDisplay = $(`${this.props.containerId} .current-slide-display`)
      this.changeBackgroundOpacity(tableToDisplay, this.props.slide, this.props.blueBox.colorTheme)
      if (this.state.applyUSGATheme && this.state.matchPlay) {
        changeMatchBackgroundOpacity(this.state.applyUSGATheme, containerSelector, this.props.slide, this.props.blueBox.colorTheme)
      }
      return
    }
    if (this.props.isFireTv && document.readyState === COMPLETE && this.state.needToFetchAgain) {
      this.setState({needToFetchAgain: false}, () => {
        this.computePlaceholderValues()
        this.computeDivisionFlightNames()
        this.injectHtmlInPage()
        this.fetchCurrentPage()
        this.updateTickerDisplay()
        this.updateScrollRate()
      })
      return
    }
    if (hasFontSizeChanged) {
      this.fetchCurrentPage()
      return
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    clearInterval(this.pageScrollInterval)
  }

  render() {
    const { blueBox } = this.props
    const { applyUSGATheme } = this.state
    const leaderboardClasses = classNames({
      'tv-event': true,
      'clearfix': true,
      'usga-theme': applyUSGATheme,
    })

    return <div style={{ flexDirection: 'column' }} className={leaderboardClasses}><div style={{
          textAlign: `${blueBox.tickerDisplay !== 'scrolling' ? blueBox.tickerDisplay : '' }`,
        }} className="ticker-display"><p style={{ animation: `${blueBox.tickerDisplay === 'scrolling' ? 'scroll-left 25s linear infinite' : '' }` }}>{ 'Ticker content' }</p></div><div className="current-slide-display"></div><div className="current-slide-data"></div></div>
  }
}

LeaderboardComponent.propTypes = {
  isFireTv: PropTypes.bool,
  slide: PropTypes.object.isRequired,
  containerId: PropTypes.string,
  minHeights: PropTypes.object,
  blueBox: PropTypes.object,
  moveToNextSlide: PropTypes.func.isRequired,
  setPlaceholderValues: PropTypes.func,
  hasUSGATheme: PropTypes.bool.isRequired,
  backgroundOpacity: PropTypes.string,
}

LeaderboardComponent.defaultProps = {
  isFireTv: false,
  containerId: '',
  minHeights: {
    flightsHeader: 0,
    header: 0,
    row: 0,
  },
  hasUSGATheme: false,
}

export default LeaderboardComponent
